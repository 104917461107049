import ListAccumulate from '@/views/dashboard/components/ListAccumulate.vue'
import ManifestInfo from '@/views/dashboard/components/ManifestInfo.vue'
import SearchReceipt from '@/views/dashboard/components/SearchReceipt.vue'
import ReceiveItemTable from '@/views/dashboard/table-tabs/ReceiveItemTable.vue'
import DeliveryItemTable from '@/views/dashboard/table-tabs/DeliveryItemTable.vue'
import PendingTable from '@/views/dashboard/table-tabs/PendingTable.vue'
import RefundTable from '@/views/dashboard/table-tabs/RefundTable.vue'
import LabelStickerAvailable from '@/views/dashboard/components/LabelStickerAvailable.vue'
import PickupInfo from '@/views/dashboard/components/PickupInfo.vue'
import {
  ref, getCurrentInstance, onMounted, computed,
  defineComponent
} from 'vue'
import moment from 'moment'
import { useStore } from 'vuex'
import {
  receiveItemUseCase, dashboardUseCase
} from '@/domain/usecase'
import TableDeliveryPending from '@/views/transactions/delivery-item/delivery-pending/components/TableDeliveryPending.vue'
import Permision from '@/utils/enum/permissions'
import { checkPermission } from '@/utils/helpers/checkPermission'

const modules = 'dashboard'

export default defineComponent({
  components: {
    ListAccumulate,
    SearchReceipt,
    ReceiveItemTable,
    DeliveryItemTable,
    PendingTable,
    RefundTable,
    LabelStickerAvailable,
    TableDeliveryPending,
    ManifestInfo,
    PickupInfo
  },
  setup(props, { emit }) {
    const app = getCurrentInstance()
    const { $confirm, $toast, $strInd } = app!.appContext.config.globalProperties
    const store = useStore()
    const indexTab = ref(0)
    const receiveItem = ref()
    const deliveryItem = ref()
    const pending = ref()
    const refund = ref()
    const dialogDetail = ref(false)
    const dialogReceipt = ref(false)
    const dialogHandoverItem = ref(false)
    const dialogPending = ref(false)
    const selectReceipt = ref(null) as any
    const supportRefund = ref(false)
    const dataIncome = ref()
    const isGetDashboard = ref(false)
    const tabsHeader = ref([
      {
        key: 1,
        title: 'Paket Dikirim'
      },
      {
        key: 0,
        title: 'Paket Diterima'
      },
      {
        key: 2,
        title: 'Paket Pending'
      },
      {
        key: 3,
        title: 'Paket Refund'
      },
    ])
    const lastReload = computed(() => store.state[modules].lastReload)

    const getDashboardPendapatan = async () => {
      const {
        error,
        result
      } = await dashboardUseCase.getDashboardSummary()
      if (!error) {
        dataIncome.value = result
        store.dispatch(`${modules}/setIncome`, result.Commission)
        store.dispatch(`${modules}/setDeliveryTotal`, result.DeliveryCount)
        store.dispatch(`${modules}/setReceiveTotal`, result.ReceptionCount)
        store.dispatch(`${modules}/setClaimTotal`, result.Claim)
        store.dispatch(`${modules}/setPendingDelivery`, {
          waybillsCount: result.PendingDelivery.WaybillsCount,
          goodsCount: result.PendingDelivery.GoodsCount
        })
        store.dispatch(`${modules}/setPendingPickup`, {
          waybillsCount: result.PendingPickups.WaybillsCount,
          goodsCount: result.PendingPickups.GoodsCount
        })
        store.dispatch(`${modules}/setLastReload`, {
          firstReload: true,
          date: moment().format('YYYY-MM-DD HH:mm:ss')
        })
      }
    }

    const getLabelStock = async () => {
      const {
        error,
        result
      } = await dashboardUseCase.getLabelStock()
      if (!error) {
        store.dispatch(`${modules}/setStockLabel`, {
          Stock: result.Total,
          Labels: result.Details
        })
      }
    }

    const getStickerStock = async () => {
      const {
        error,
        result
      } = await dashboardUseCase.getSticketStock()
      if (!error) {
        store.dispatch(`${modules}/setStockSticker`, {
          Stock: result.Total,
          Stickers: result.Details
        })
      }
    }

    const onReloadData = () => {
      // if (!lastReload.value.firstReload) {
      getDashboardPendapatan()
      getLabelStock()
      getStickerStock()
      // }
    }

    const refreshDashboard = async () => {
      getDashboardPendapatan()
      getLabelStock()
      getStickerStock()
    }

    const hideDialogHandoverItem = () => {
      dialogHandoverItem.value = false
      selectReceipt.value = null
    }

    const toEmitReloadData = () => {
      switch (indexTab.value) {
        case 0:
          deliveryItem.value.getAllData()
          break
        case 1:
          receiveItem.value.getAllData()
          break
        case 2:
          pending.value.getAllData()
          break
        case 3:
          refund.value.getAllData()
          break
        default:
          break
      }
    }

    const proccessDropAgent = async (id: any) => {
      store.dispatch('showLoading')
      const response = await receiveItemUseCase.submitDropAgent([id])
      if (response.error) {
        $toast.add({
          severity: 'error', detail: $strInd.toast.errorSubmit, group: 'bc', life: 3000
        })
      } else {
        $toast.add({
          severity: 'success', detail: $strInd.toast.successSubmit, group: 'bc', life: 3000
        })

        toEmitReloadData()
      }
      store.dispatch('hideLoading')
    }

    const submitDropAgent = (id: any) => {
      $confirm.require({
        header: 'Diterima Agen',
        message: 'Apakah anda sudah mengecek kembali pilihan resi anda? Jika sudah diproses data tidak dapat dibatalkan',
        icon: 'pi pi-info-circle',
        accept: async () => {
          proccessDropAgent(id)
        }
      })
    }

    const submitHandoverItem = (id: any) => {
      selectReceipt.value = id
      dialogHandoverItem.value = true
    }

    const hideDialogDetail = () => {
      dialogDetail.value = false
      selectReceipt.value = null
      supportRefund.value = false
    }

    const hidePending = () => {
      dialogPending.value = false
      selectReceipt.value = null
    }

    const showDetail = (id: any) => {
      selectReceipt.value = id
      supportRefund.value = false
      dialogDetail.value = true
    }

    const showRefund = (id: any) => {
      selectReceipt.value = id
      supportRefund.value = true
      dialogDetail.value = true
    }

    const showPending = (id: any) => {
      selectReceipt.value = id
      dialogPending.value = true
    }

    const scrollFunction = (evt: any, el: any) => {
      if (window.scrollY > 100) {
        isGetDashboard.value = true
      }
      // if
    }

    onMounted(() => {
      onReloadData()
    })

    return {
      indexTab,
      tabsHeader,
      receiveItem,
      deliveryItem,
      pending,
      refund,
      dialogDetail,
      dialogReceipt,
      dialogHandoverItem,
      dialogPending,
      selectReceipt,
      supportRefund,
      lastReload,
      getDashboardPendapatan,
      onReloadData,
      refreshDashboard,
      showRefund,
      showDetail,
      showPending,
      submitHandoverItem,
      hideDialogDetail,
      hideDialogHandoverItem,
      hidePending,
      submitDropAgent,
      toEmitReloadData,
      scrollFunction,
      isGetDashboard,
      checkPermission,
      Permision
    }
  }
})
